.EducationCard {
  @apply flex flex-shrink-0 flex-col items-center justify-between rounded-xl border bg-white p-2 shadow shadow-shadow-100 md:rounded-3xl md:p-4;
  h4 {
    @apply z-10 self-stretch text-left text-xl font-semibold text-primary-purple-blue-300 md:text-2xl;
  }
  img {
    @apply z-0 -my-2 md:-my-6;
  }
  span {
    @apply z-10 self-stretch text-right text-base font-semibold text-secondary-dark-grey-500;
  }
}
