.Education {
  @apply grid grid-cols-2 flex-wrap content-stretch items-stretch gap-2 md:gap-4 lg:grid-cols-4;
  @apply text-base font-medium text-secondary-grey-800 md:text-xl;
  h3 {
    @apply text-2xl font-bold text-primary-purple-blue-500 md:text-4xl;
  }

  &__main {
    @apply col-span-2 flex flex-col gap-2 p-0 md:p-4;
  }
  &__item {
    @apply flex h-full flex-col items-center justify-center rounded-3xl border bg-white p-4 text-center shadow shadow-shadow-100;
    span {
      @apply self-stretch text-base font-semibold text-secondary-dark-grey-500;
    }
    a {
      @apply flex flex-col items-start gap-2.5 text-primary-purple-blue-500;
    }
  }
  button {
    @apply h-full max-h-full rounded-3xl font-medium;
  }
}
