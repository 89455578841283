.Slider {
  @apply relative flex flex-col items-center text-center font-medium text-primary-purple-blue-100 md:text-xl lg:text-2xl;
  @apply overflow-hidden rounded-[30px] bg-cover bg-center bg-no-repeat;
  @apply h-[50vh] min-h-[450px] w-full p-4;
  @apply md:h-[calc(100vh-300px)] md:min-h-[450px] md:p-9;
  @apply lg:h-[calc(100vh-200px)] lg:min-h-[600px] lg:px-10 lg:py-6;
  @apply 3xl:h-[calc(100vh-150px)] 3xl:min-h-[600px] 3xl:px-56 3xl:py-16;

  &__content {
    @apply relative h-full w-full overflow-hidden;

    hr {
      @apply m-auto my-2 w-28 md:my-4 lg:my-6;
    }

    h2 {
      @apply mb-3 text-2xl font-medium md:mb-7 md:text-4xl lg:mb-14 lg:text-5xl;
    }
  }

  &__slide {
    @apply absolute bottom-0 left-0 top-0 flex h-full w-full flex-col items-center justify-center ring-0;
    transition: opacity 0.7s ease;
  }

  &__controls {
    @apply relative mb-4 flex w-full items-center justify-between md:mb-6 lg:mb-8 xl:mb-10;
  }

  &__control {
    @apply absolute left-0 right-0 m-auto w-1/3 cursor-pointer px-4 py-2 text-white;
    @apply flex-[1_0_0] text-center text-sm opacity-50 hover:opacity-100 md:text-lg lg:text-2xl;
    transition: transform 0.7s ease;

    &.active {
      @apply flex-[1_0_0] text-center text-base opacity-100 backdrop-filter md:text-xl lg:text-3xl;
      transform: translateX(0%);
    }
    &.left {
      transform: translateX(-100%);
    }
    &.right {
      transform: translateX(100%);
    }
  }
}
