.b-actual-carousel {
  .carousel-slider {
    height: 200px;
    max-width: 365px;
  }

  .carousel .control-dots .dot {
    background-color: #c9d4ea;
    width: 4px;
    height: 4px;
    box-shadow: none !important;
    margin: 0 4px;
  }

  .carousel .control-dots .dot.selected,
  .carousel .control-dots .dot:hover {
    box-shadow: none !important;
    background-color: #4318ff !important;
  }
}
