.b-multi-base {
  outline: none !important;
  box-shadow: none !important;
  &.disabled [class*='-control'] {
    background-color: #f6f8fd !important;
  }

  [class*='-control'] {
    border: none;
    outline: none !important;
    box-shadow: none !important;
    border-radius: 16px;
    width: 100%;
    background-color: transparent;
  }

  [class*='-placeholder'] {
    color: #1b2559;
  }

  [class*='-multiValue'] {
    background-color: #e9e3ff;
    border-radius: 9999px;
    overflow: hidden;

    [class*='-MultiValueGeneric'] {
      color: #4318ff;
      font-weight: 600;
    }

    [class*='-MultiValueRemove'] {
      color: #4318ff;

      &:hover {
        background-color: transparent;
      }
    }
  }
}
