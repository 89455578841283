.QuizDayCard {
  @apply flex min-h-[100px] flex-[1_0_0] flex-col items-center justify-center gap-1 rounded-[20px] border-2 bg-primary-white p-2;
  @apply relative cursor-pointer text-xs font-semibold text-secondary-dark-grey-400;

  h3 {
    @apply text-lg font-bold text-secondary-dark-grey-500;
  }

  &.earned {
    @apply border-primary-green-500 text-primary-green-500;
    h3 {
      @apply text-primary-green-700;
    }
  }

  &.new {
    @apply border-transparent bg-primary-purple-blue-100 text-primary-purple-blue-300;
    h3 {
      @apply text-primary-purple-blue-500;
    }
  }

  &.current {
    @apply border-primary-purple-blue-400  font-bold text-primary-purple-blue-300;
    h3 {
      @apply text-primary-purple-blue-500;
    }
  }

  &__icon {
    @apply absolute right-2 top-2 fill-primary-purple-blue-500;
  }
}
