.CourseBuyModal {
  @apply flex flex-col items-center justify-center gap-3 md:flex-row md:gap-5;
  @apply -mt-9 text-sm font-medium leading-5 text-black-10 md:-mt-12 md:text-base;

  img {
    @apply w-full md:min-w-[365px] md:p-0;
  }

  h3 {
    @apply flex-[1_0_0] text-lg font-bold leading-7 text-black-10 md:text-2xl;
  }

  &__content {
    @apply col-span-2 flex flex-col justify-between gap-3 self-stretch md:gap-6;
  }

  &__btns {
    @apply flex flex-col items-end gap-3 md:flex-row md:gap-4 md:rounded-3xl md:p-6 md:shadow-md md:shadow-shadow-700;
  }
}
