.PublicProfileInfo {
  @apply flex h-full flex-col justify-between gap-4 text-xs font-normal text-secondary-dark-grey-700 md:gap-6 md:text-base;

  &__head {
    @apply flex items-center justify-between;
    h4 {
      @apply text-base font-semibold text-secondary-grey-900 md:text-xl;
    }
    span {
      @apply text-[10px] font-semibold text-secondary-dark-grey-500 md:text-xs;
    }
  }

  &__cards {
    @apply flex w-full flex-col gap-x-5 gap-y-2 md:grid-cols-2 md:gap-y-[22px] md:p-0;

    & > div {
      @apply flex flex-col gap-x-5 gap-y-2 md:flex-row;
      & > div {
        @apply flex w-full flex-col gap-1 rounded-2xl p-2 text-xs font-medium text-secondary-dark-grey-500 shadow-md shadow-shadow-600 md:p-4 md:text-sm;
        h5 {
          @apply text-sm font-semibold text-secondary-grey-900 md:text-base;
        }
      }
    }
  }
}
