.ProfilePostCard {
  @apply flex items-center justify-between gap-2 rounded-2xl shadow-card shadow-shadow-700 md:gap-4;
  @apply p-2 text-xs font-medium text-secondary-dark-grey-500 md:p-[14px] md:text-sm;

  a {
    @apply text-primary-purple-blue-500 visited:text-primary-purple-blue-400 hover:text-primary-purple-blue-700;
  }

  &__img {
    @apply h-[60px] w-[60px] flex-none overflow-hidden rounded-lg md:h-20 md:w-20;
    img {
      @apply block h-full w-full object-cover;
    }
  }

  &__content {
    @apply w-full overflow-hidden overflow-ellipsis text-nowrap;
    h6 {
      @apply overflow-hidden overflow-ellipsis text-sm font-medium text-secondary-grey-900 md:text-base;
    }
    span {
      @apply overflow-hidden overflow-ellipsis text-nowrap;
    }
  }

  &__icon {
    @apply flex-none;
    svg {
      @apply h-[14px] w-[14px] md:h-[16.8px] md:w-[16.8px] md:p-0;
    }
  }
}
