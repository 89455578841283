.Possibilities {
  @apply flex flex-col items-center gap-4 self-stretch rounded-[30px] md:gap-6 md:p-10;
  h2 {
    @apply text-center text-2xl font-semibold text-black md:text-4xl;
  }

  &__wrapper {
    @apply grid grid-cols-2 items-start justify-center gap-2 self-stretch md:gap-6 lg:grid-cols-3;
  }
}
