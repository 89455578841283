.ProfileCard {
  &__banner {
    @apply relative flex w-full items-center justify-center bg-cover;
    @apply mb-14 h-[88px] rounded-[20px] md:h-[104px] lg:h-[128px] lg:p-0;
  }

  &__avatar {
    @apply absolute -bottom-10 flex  items-center justify-center rounded-full;
    @apply h-[60px] w-[60px] md:h-[86px] md:w-[86px] md:p-0;
  }

  &__edit {
    @apply absolute -bottom-1 -right-1 z-10 flex h-5 w-5 cursor-pointer items-center justify-center rounded-full bg-primary-purple-blue-500 md:-bottom-2 md:-right-2 md:h-7 md:w-7;
    svg {
      @apply h-2 w-2 md:h-3 md:w-3;
    }
  }

  &__user {
    @apply flex flex-col items-center text-xs text-secondary-dark-grey-500 md:text-base;
    h1 {
      @apply text-lg font-bold text-secondary-grey-900 md:text-3xl;
    }
  }
}
