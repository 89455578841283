.Modal {
  &__bg {
    @apply z-50 min-w-80 bg-[#161C5140] p-0;
  }
  &__content {
    @apply m-auto w-max max-w-fit;
  }

  &__title {
    @apply flex items-center justify-between gap-2;
    h3 {
      @apply text-base font-bold text-secondary-grey-900 md:text-lg;
    }
  }

  &__close {
    @apply relative z-50 ml-auto flex items-center justify-center rounded-full;
    @apply bg-primary-white text-primary-purple-blue-500 shadow-sm shadow-shadow-600;
  }

  &__body {
    @apply mx-2 flex h-full flex-col gap-2 overflow-y-auto rounded-2xl bg-white p-2 sm:min-w-96 sm:gap-5 sm:p-5 sm-max:w-[calc(100vw-16px)] md:rounded-[20px] md:p-6;
  }
}

.chakra-modal__content-container {
  height: 100% !important;
}
