.MobileTab {
  @apply flex w-full flex-[1_0_0] cursor-pointer flex-col items-center gap-0.5 p-1;
  @apply justify-center rounded-lg text-center text-xs font-semibold text-secondary-grey-600;
  &__icon {
    @apply h-[22px] w-[22px] fill-secondary-grey-600 p-0;
    &.active {
      @apply fill-secondary-dark-grey-900;
    }
    &.secondary {
      @apply fill-primary-purple-blue-500;
    }
  }
  &.active {
    @apply text-secondary-grey-900;
    svg {
      @apply fill-secondary-dark-grey-900;
    }
  }
  &.secondary {
    @apply bg-primary-purple-blue-100 text-primary-purple-blue-500;
  }

  &__collapse {
    @apply absolute bottom-[70px] left-2 flex w-[calc(100vw-16px)] flex-col items-start gap-1 rounded-2xl bg-white p-2 shadow shadow-shadow-700;
    &.active {
      @apply fill-secondary-dark-grey-900;
    }
  }

  &__item {
    @apply flex items-center gap-2 self-stretch rounded-2xl p-1 text-left text-[10px] font-medium text-secondary-dark-grey-500;
    .icon {
      @apply flex flex-col items-center justify-center gap-1.5 rounded-2xl bg-white p-2 shadow shadow-shadow-700;
    }
    h5 {
      @apply text-xs font-medium text-secondary-dark-grey-800;
    }
  }
}
