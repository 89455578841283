.QuizzesQuestion {
  @apply flex items-center justify-between gap-16;

  &__wrapper {
    @apply flex h-fit flex-col justify-center gap-[60px] md:gap-10;
  }

  &__number {
    @apply flex items-center justify-between text-sm font-semibold text-primary-purple-blue-600;
    span {
      @apply rounded-[20px] bg-primary-purple-blue-100 px-4 py-2;
    }
    img {
      @apply block w-[33px] p-0 md:hidden;
    }
  }

  &__question {
    @apply flex items-center;
  }

  &__image {
    @apply hidden h-[282px] w-[282px] flex-none items-center justify-center bg-contain bg-no-repeat md:flex;
    background-image: url('../../../../assets/images/question.png');

    img {
      @apply w-[133px] p-0;
    }
  }
}
