@import '../../../../styles/varibles';

.b-topic {
  padding: 15px;
  background-color: $gray-75;
  border-radius: 12px;

  &__note {
    font-size: 12px;
    font-weight: 600;
    max-width: 500px;
    margin-left: 15px;
    margin-top: 15px;
    margin-bottom: 10px;
    color: #899197;
  }
}

.b-question {
  position: relative;
  padding: 20px;
  background-color: $gray-75;
  border-radius: 12px;
  overflow: hidden;

  &__bg {
    position: absolute;
    width: 6px;
    height: 100%;
    left: 0;
    top: 0;
    background-color: $green-300;
  }

  &__item {
    &__green-line {
      height: 2px;
      background-color: $green-300;
    }

    &__green-btn {
      color: $green-300;
      font-size: 18px;
      font-weight: 500;
      cursor: pointer;
    }

    &__choices {
      &__green-btn {
        color: #52a31d;
        font-size: 12px;
        font-weight: 500;
        margin-left: 20px;
        margin-top: 10px;
        cursor: pointer;
      }
    }
  }
}
