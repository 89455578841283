@import 'src/styles/varibles';

.mediaViewer {
  @apply flex w-full flex-col-reverse gap-[22.5px] p-0;

  &__bg {
    @apply rounded-3xl bg-cover bg-center bg-no-repeat shadow-card shadow-shadow-700 md:rounded-[30px] md:p-0;
    @apply h-[200px] overflow-hidden sm:h-[300px] md:h-[300px] lg:h-[400px] xl:h-[500px] xl:p-0;
  }

  &__content {
    transition:
      width 0.5s,
      height 0.5s;
    &.collapsed {
      @apply h-0 w-0 overflow-hidden;
    }
  }

  &__movie {
    @apply h-[200px] overflow-hidden rounded-3xl bg-[#C4C4C4] sm:h-[300px] md:h-[300px] md:rounded-[30px] lg:h-[400px] xl:h-[500px] xl:p-0;
  }

  &__btn_download {
    margin-top: 22.5px;
  }
}
