.QuizCategories {
  @apply flex w-full flex-col content-start items-start gap-2 md:gap-5;

  &__nav {
    @apply flex w-full gap-2 md:gap-5;
    div {
      @apply flex w-full items-center justify-center gap-2.5 rounded-3xl bg-primary-purple-blue-100 px-2.5 py-4 md:p-6;
      @apply cursor-pointer text-center text-sm font-semibold text-primary-purple-blue-400 md:text-xl md:font-bold;
      @apply hover:bg-primary-purple-blue-300 hover:text-primary-purple-blue-100;

      &.active {
        @apply bg-primary-purple-blue-500 text-primary-purple-blue-100;
      }
    }
  }

  &__content {
    @apply grid w-full grid-cols-2 flex-wrap content-start items-start gap-2 md:grid-cols-3 md:gap-5;
    div {
      @apply flex w-full flex-shrink-0 flex-col items-center gap-4 rounded-[32px] bg-primary-white p-4;
      @apply text-center text-sm font-semibold text-primary-purple-blue-600 md:text-lg;
      @apply cursor-pointer shadow-md shadow-shadow-700 hover:shadow-lg;
    }
  }
}
