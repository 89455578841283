.ShareButtons {
  @apply mt-5 flex flex-col justify-center gap-5;

  span {
    @apply text-xs font-medium text-secondary-dark-grey-500;
  }

  &__button {
    @apply flex flex-col items-center gap-2;
    @apply text-[10px] font-semibold text-primary-purple-blue-900;
  }

  &__wrapper {
    @apply flex justify-between space-x-4;
  }

  &__icon {
    @apply flex items-center justify-center overflow-hidden rounded-full;
    img {
      @apply bg-secondary-dark-grey-100 p-4 md:p-6;
    }
  }

  &__copy {
    @apply mt-2 flex items-center justify-center gap-2 self-stretch rounded-2xl bg-primary-purple-blue-100 px-4;
    span {
      @apply w-56 overflow-hidden overflow-ellipsis whitespace-nowrap text-base font-semibold text-primary-purple-blue-500;
    }
    svg {
      @apply cursor-pointer;
    }
  }
}
