.AuthLayout {
  @apply flex w-full flex-row items-center justify-center sm-max:p-3.5 lg:justify-between;

  &__title {
    @apply flex flex-col gap-2;
    h1 {
      @apply text-[32px] font-semibold text-secondary-dark-grey-900 sm-max:text-2xl;
    }
    span {
      @apply text-sm text-secondary-dark-grey-500 md:text-base;
    }
  }

  &__wrapper {
    @apply flex flex-col gap-4 sm:gap-8;
    @apply z-10 w-full rounded-3xl bg-white p-4 shadow-card shadow-shadow-700 sm:mx-auto sm:w-[512px] sm:max-w-lg sm:rounded-[30px] sm:p-11;
  }

  &__bg {
    @apply fixed left-0 top-0 z-0 h-1/2 w-full rounded-bl-3xl rounded-br-3xl object-fill md:rounded-bl-[30px] md:rounded-br-[30px] md:object-fill;
  }
}
