.EarnMoney {
  @apply gap-2 rounded-2xl px-8 py-6 md:min-h-[457px] md:gap-6 md:rounded-[30px] md:px-[73px] md:py-14;
  @apply relative grid self-stretch bg-cover bg-center bg-no-repeat lg:grid-cols-2;

  &__content {
    @apply flex flex-[1_0_0] flex-col items-start gap-2 text-lg font-medium text-white md:gap-2.5 md:text-2xl;

    h2 {
      @apply text-2xl font-black md:text-4xl;
    }
  }

  &__coins {
    @apply relative mr-12 mt-4 flex min-h-80 justify-end space-x-4;

    img {
      @apply absolute;

      &:first-child {
        @apply top-0 w-2/3 md:-top-12 md:w-2/5 lg:-top-24 lg:w-auto;
      }

      &:nth-child(2) {
        @apply -left-20 top-36 w-1/2 md:w-1/3 lg:left-0 lg:top-20 lg:w-auto;
      }

      &:last-child {
        @apply -bottom-10 -right-16 w-1/2 md:-bottom-20 md:-right-20 md:w-1/3 lg:w-auto;
      }
    }
  }
}
