@import '../../../../styles/varibles';

.b-modal {
  width: 600px;
  margin-top: 100px;
  margin-left: -300px;

  &__img {
    margin-top: 50px;
  }

  &__text {
    font-size: 31px;
    font-weight: 600;
    margin-top: 10px;
  }

  &__file {
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 0;
  }
}
