@import 'varibles';

.g-label {
  font-size: 16px;
  margin-bottom: 4px;
  font-weight: 500;
  color: #1a1c1e;

  &--required {
    color: #d91f11;
  }
}

.g-link {
  color: $primary;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
  text-decoration: none;
}

.g-shadow-text {
  color: $gray-500;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.g-text {
  color: $black;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.g-primary-text {
  color: $primary;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 0;
}

.g-main-title {
  @apply mb-0 text-2xl font-bold text-secondary-grey-900 md:text-4xl;
}

.g-small-title {
  color: $black;
  margin-bottom: 0;
  @apply text-sm font-medium md:text-xl;
}

.g-error {
  font-size: 0.875em;
  color: red;
}

.g-title-18 {
  color: $black;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 0;
}

.g-fs-28 {
  font-size: 28px;
}

.g-fs-24 {
  font-size: 24px;
}

.g-fs-12 {
  font-size: 12px;
}

.cursor-pointer {
  cursor: pointer;
}

.g-btn-opacity {
  font-weight: 600;
  font-size: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 10px;
  height: 35px;
  border-radius: 10px;
  background-color: rgba(255, 255, 255, 0.3);
  color: white;
  text-decoration: none;
}

.g-error {
  font-size: 14px;
  font-weight: 400;
  color: red;
  margin-bottom: 0;
}

.mxw-500 {
  max-width: 500px;
}

.mxw-900 {
  max-width: 900px;
}

.d-hidden {
  display: none !important;
}

.b-paginate {
  list-style: none;
  display: flex;
  column-gap: 10px;

  li {
    a {
      width: 30px;
      height: 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 5px;
      background-color: $gray-300;
      color: $black !important;
    }

    &.selected a {
      background-color: $black;
      color: white !important;
    }

    &.previous a,
    &.next a {
      background-color: $black;
      color: white !important;
    }
  }
}

.ml-n3 {
  margin-left: -1rem !important;
}

.w-246px {
  width: 246px !important;
}

::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: rgba(218, 218, 218, 0.459);
  border-radius: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #4318ff;
  border-radius: 10px;
}

.content {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-3 font-bold;
  }

  p {
    @apply mb-3 indent-5;
  }

  ul {
    @apply mb-3 list-disc pl-10;
  }
  ol {
    @apply mb-3 list-decimal pl-10;
  }

  b,
  strong {
    @apply font-bold;
  }

  i {
    @apply italic;
  }

  u {
    @apply underline;
  }
}
