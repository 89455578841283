.Notifications {
  @apply flex max-h-96 w-80 flex-col items-start gap-4 rounded-3xl bg-white p-4 shadow-md shadow-shadow-700;

  h3 {
    @apply text-xl font-bold text-secondary-dark-grey-900;
  }

  &__wrapper {
    @apply flex w-full flex-col gap-4 overflow-auto pr-4;

    span {
      @apply text-sm font-semibold text-secondary-dark-grey-400;
    }
  }
}
