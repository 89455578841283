.MultiSearch {
  @apply relative flex w-full items-center gap-1 p-1;

  &__options {
    @apply flex flex-nowrap gap-2;
  }

  input {
    @apply box-border w-full bg-transparent p-2 outline-none;
  }

  &__dropdown {
    @apply absolute top-14 z-50 max-h-60 w-full overflow-y-auto;
    @apply flex flex-col items-start gap-2 rounded-2xl bg-white py-4 shadow-md shadow-shadow-700;
    @apply flex-[1_0_0] text-sm font-medium text-secondary-dark-grey-800;

    div {
      @apply flex cursor-pointer items-center justify-between self-stretch px-4 py-2 hover:bg-primary-purple-blue-100;
      span {
        @apply flex flex-col items-center justify-center gap-2.5 rounded-3xl bg-secondary-grey-300 px-2;
        @apply text-xs font-semibold text-secondary-grey-700;
      }
    }
  }
}
