.SocialBlock {
  @apply mx-auto flex max-w-5xl flex-col items-center gap-3 self-stretch rounded-[30px] text-center text-base text-secondary-grey-800 md:gap-7 md:py-10 md:text-xl;
  &__content {
    @apply flex flex-col items-center gap-2 md:px-10 lg:px-20 xl:px-32;
  }
  h3 {
    @apply text-center text-2xl font-semibold text-black md:text-4xl;
  }
  &__social {
    @apply flex items-start justify-center gap-2 self-stretch sm-max:flex-wrap md:gap-6;
  }
}
