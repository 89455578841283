.WizBlock {
  @apply flex flex-col items-center justify-between gap-4 self-stretch rounded-[30px] border bg-white p-3 shadow-md shadow-shadow-700 md:flex-row md:gap-6 md:p-6;
  @apply text-base font-medium text-secondary-grey-800 md:text-lg lg:text-2xl;

  h3 {
    @apply text-xl font-semibold text-black md:text-2xl lg:text-[32px] lg:opacity-100;
  }

  &__content {
    @apply flex w-full flex-[1_0_0] flex-col items-start gap-1 md:gap-2.5;
  }

  button {
    @apply font-medium;
  }
}
