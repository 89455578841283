.QuizzesAnswers {
  @apply grid w-full items-center gap-3 sm:grid-cols-2;

  button {
    @apply h-max w-auto justify-start rounded-[30px] bg-primary-purple-blue-100 px-8 py-3 text-left text-sm font-semibold;
    &.active,
    &:hover {
      @apply bg-primary-purple-blue-900 text-white;
    }
  }
}
