.ScienceCard {
  @apply flex flex-shrink-0 items-center gap-2 rounded-xl border bg-white p-2 shadow shadow-shadow-700 md:gap-4 md:rounded-3xl md:p-4;

  &__icon {
    @apply flex items-center justify-center gap-2 rounded-2xl bg-white p-3 shadow-md shadow-shadow-700 md:p-6;
  }

  &__content {
    @apply flex flex-col items-start text-xs font-medium text-secondary-grey-800 md:gap-2.5 md:text-base;
    h4 {
      @apply text-lg font-medium text-black md:text-2xl;
    }
  }
}
