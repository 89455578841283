.CoursePreview {
  @apply flex flex-col gap-2 md:gap-6;

  &__content {
    @apply grid grid-cols-1 gap-2 md:grid-cols-10 md:gap-5;
  }
  &__list {
    @apply col-span-1 flex flex-col gap-2 md:col-span-6 md:gap-5 xl:col-span-7;
  }
  &__aside {
    @apply col-span-1 flex flex-none flex-col items-start gap-2 md:col-span-4 md:gap-5 xl:col-span-3;
  }

  &__head {
    @apply flex items-center justify-between gap-2 bg-white p-2 shadow-card shadow-shadow-700 md:gap-6 md:rounded-[30px] md:p-4;
  }
  &__user {
    @apply flex items-center gap-3 md:gap-4;
    span {
      @apply text-xs font-medium text-secondary-dark-grey-500 md:text-base;
      a {
        @apply text-secondary-dark-grey-500;
      }
    }

    img {
      @apply h-[34px] w-[34px] rounded-full md:h-[46px] md:w-[46px] md:p-0;
    }

    h4 {
      @apply self-stretch overflow-hidden overflow-ellipsis text-sm font-bold text-secondary-grey-900 md:text-lg;
      a {
        @apply text-secondary-grey-900;
      }
    }
    & > div {
      @apply flex flex-col md:gap-1;
      & > div {
        @apply flex flex-wrap gap-1 md:gap-1.5;
      }
    }
  }

  &__info {
    @apply flex flex-col gap-4 self-stretch rounded-2xl bg-white p-2 shadow-card shadow-shadow-700 md:gap-6 md:rounded-[30px] md:px-10 md:py-6;
    @apply self-stretch overflow-hidden text-sm font-medium text-secondary-grey-900 md:text-lg;
  }
}
