.b-carousel {
  &__wrapper {
    position: relative;

    img {
      border-radius: 12px;
    }
  }

  .carousel.carousel-slider .control-arrow,
  .carousel-slider {
    border-radius: 12px;
  }

  .thumbs-wrapper {
    display: none;
  }

  &__item {
    &__wrapper {
      display: flex;
      flex-direction: column;
      position: absolute;
      bottom: 60px;
      left: 45px;
    }

    &__icon {
      width: 56px;
      height: 56px;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(255, 255, 255, 0.25);
      margin-bottom: 6px;
    }

    &__title {
      color: white;
      font-size: 40px;
      font-weight: 600;
      margin-bottom: 6px;
    }

    &__desc {
      color: white;
      font-size: 24px;
      font-weight: 500;
      opacity: 0.5;
      text-align: left;
    }
  }
}

.b-started-courses {
  display: flex;
  flex-direction: row;
  column-gap: 25px;
}

.b-all-course {
  row-gap: 20px;
  justify-content: flex-start;
  column-gap: 20px;
}
