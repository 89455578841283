.Scoreboard {
  @apply flex flex-col items-center gap-6;

  &__logo {
    @apply flex flex-col items-center gap-2;

    h1 {
      @apply text-2xl font-semibold text-[#1A1C1E] md:p-0;
    }
  }

  &__board {
    @apply flex w-full flex-col gap-4 md:w-[740px] md:gap-8 lg:w-[820px];
    @apply z-10 -mt-12 rounded-[32px] bg-white p-4 shadow-md shadow-shadow-600 md:px-12 md:py-6;

    &-item {
      @apply flex items-center justify-between gap-4;
      @apply text-sm font-bold text-secondary-grey-900 md:text-lg;

      img {
        @apply h-[34px] w-[34px] rounded-full bg-white object-cover md:h-[46px] md:w-[46px] md:p-0;
      }

      span {
        @apply text-xs font-medium text-secondary-dark-grey-500 md:text-base;
      }

      svg {
        @apply w-[10px] md:w-3;
      }
    }
  }
}
