.NFTViewSchool {
  @apply flex flex-col gap-3 md:gap-5;

  &__wrapper {
    @apply flex grid-cols-1 flex-col items-start justify-center gap-3 self-stretch md:grid-cols-10 md:gap-5 lg:grid;

    a {
      @apply text-center text-xs font-semibold text-primary-purple-blue-400 md:text-sm;
      svg {
        @apply fill-primary-purple-blue-400;
      }
    }
  }

  &__left {
    @apply col-span-1 flex w-full flex-col items-start gap-[18px] md:col-span-4;

    & > div {
      @apply w-full rounded-2xl bg-white p-3 shadow-md shadow-shadow-700 md:rounded-3xl md:p-4;
    }
  }
  &__units {
    @apply col-span-1 w-full sm-max:border md:col-span-6;
    @apply flex flex-[1_0_0] flex-col items-start justify-center gap-3 rounded-2xl bg-white p-0 pt-3 shadow-md shadow-shadow-700 md:rounded-3xl md:p-4;
  }

  h3 {
    @apply text-base font-bold text-secondary-dark-grey-900 md:text-lg;
  }

  h4 {
    @apply flex w-full flex-[1_0_0] items-center justify-between gap-3 text-sm font-bold text-secondary-dark-grey-900 md:text-base;
    span {
      @apply flex-[1_0_0] text-right text-xs font-medium text-secondary-dark-grey-500 md:text-sm;
      @apply cursor-pointer hover:text-primary-purple-blue-500;
    }
  }

  &__item {
    @apply flex items-center gap-1 self-stretch border-t bg-white p-3 md:p-5;
    @apply cursor-pointer text-sm font-semibold text-secondary-grey-900 hover:bg-secondary-grey-100 md:text-base;

    &:last-child {
      @apply rounded-b-3xl;
    }
  }

  h5 {
    @apply flex-[1_0_0] text-sm font-semibold md:text-base;
  }

  &__gpa {
    @apply text-center text-3xl font-bold text-secondary-dark-grey-500 md:text-4xl;
    &.good {
      @apply text-primary-green-500;
    }
    &.normal {
      @apply text-primary-orange-500;
    }
    &.bad {
      @apply text-primary-red-500;
    }
  }
}
