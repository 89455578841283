.ContentWithIconCard {
  @apply flex flex-[1_0_0] items-start justify-center gap-2 rounded-[22px] bg-white p-2 md:gap-4 md:p-5;
  @apply self-stretch text-sm font-medium text-secondary-grey-800 shadow-md shadow-shadow-700 md:text-base;

  &__icon {
    @apply flex items-center justify-center gap-2 rounded-2xl bg-primary-purple-blue-100 p-3 shadow-md shadow-shadow-500 md:p-4;
    img {
      @apply max-h-5 max-w-5;
    }
  }

  &__content {
    @apply flex flex-[1_0_0] flex-col items-start md:gap-2;
  }

  h3 {
    @apply text-center text-xl font-medium text-black md:text-2xl;
  }
}
