.SimpleModal {
  &__bg {
    @apply z-50 min-w-80 bg-shadow-700;
  }
  &__content {
    @apply m-auto mt-5 w-max max-w-fit;
  }

  &__close {
    @apply absolute right-6 top-5 z-10 flex items-center justify-center gap-[6px] rounded-2xl p-2;
    @apply bg-primary-white text-primary-purple-blue-500 shadow-sm shadow-shadow-600;
  }

  &__body {
    @apply h-full overflow-y-auto rounded-xl bg-white p-6;
  }
}
