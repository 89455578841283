.Performance {
  @apply flex flex-col gap-6 md:gap-12;
  &__wrapper {
    @apply flex max-w-[560px] flex-col items-center justify-center gap-[74px] p-0 sm:mx-auto;

    h3 {
      @apply mt-6 text-center font-bold text-secondary-dark-grey-900;
    }
  }
  &__buttons {
    @apply flex w-full gap-[10px] p-0;
    button {
      @apply w-full;
    }
  }
}
