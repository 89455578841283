@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

// rm in future horizon
body {
  margin: 0;
  font-family:
    Montserrat,
    -apple-system,
    BlinkMacSystemFont,
    'Segoe UI',
    'Roboto',
    'Oxygen',
    'Ubuntu',
    'Cantarell',
    'Fira Sans',
    'Droid Sans',
    'Helvetica Neue',
    sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@import 'styles/varibles';
@import 'styles/global';
@import 'styles/customs';
@import '../node_modules/react-responsive-carousel/lib/styles/carousel.min.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    font-family: 'Montserrat', sans-serif !important;
    font-feature-settings: 'kern' !important;
    -webkit-font-smoothing: antialiased;
    letter-spacing: -0.2px;
  }
}

.collapse {
  visibility: visible !important;
}

svg {
  display: inline !important;
}

.border-width {
  border-width: 1px;
}

.text-color-white {
  color: white;
}

.chakra-modal__content-container {
  z-index: 100;
}

a {
  @apply text-primary-purple-blue-500;
}
