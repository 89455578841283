@import '../../../styles/varibles';

.b-steps {
  &__item {
    margin-right: 12px;

    &__number {
      width: 27px;
      height: 27px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid $gray-300;
      color: $gray-500;
      font-weight: 600;

      &--active {
        border: 1px solid $primary;
        background-color: $primary;
        color: white;
      }
    }

    &__step-name {
      margin-bottom: 0;
      font-size: 18px;
      color: $black;
      font-weight: 500;
      margin-left: 6px;
      margin-right: 7px;
    }
  }
}

.b-visual {
  &__shadow {
    font-size: 15px;
    color: $gray-500;
  }

  &__item {
    &__img {
      @apply h-[76px] w-[76px] md:h-[93px] md:w-[93px] md:p-0;
      background-color: $gray-300;
      border-radius: 9px;
      overflow: hidden;
      cursor: pointer;
      box-sizing: initial;
      outline: 2px solid white;

      &--add {
        background-color: #f0edfe;
        align-items: center;
        justify-content: center;
        display: flex;
      }

      &__active {
        outline: 2px solid $primary;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}
