.PossibilityCard {
  @apply flex flex-[1_0_0] flex-col items-center gap-1 rounded-[22px] bg-white p-2 shadow-md shadow-shadow-700 md:gap-4 md:p-5 lg:px-5 xl:px-20 3xl:px-28;
  @apply self-stretch text-center text-sm font-medium text-secondary-grey-800 md:text-base;

  &__icon {
    @apply flex items-center justify-center gap-2 rounded-2xl bg-white p-3 shadow-md shadow-shadow-700 md:p-6;
    img {
      @apply max-h-6 max-w-6;
    }
  }

  h5 {
    @apply text-center text-lg font-medium text-black md:text-2xl;
  }
}
