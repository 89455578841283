.PublicProfile {
  @apply flex flex-col gap-2 md:gap-6;

  &__content {
    @apply grid w-full gap-2 md:gap-5;
  }

  &__btns {
    @apply flex justify-between gap-2 md:gap-4;
    button {
      @apply w-full;
    }
  }

  &__card {
    @apply flex flex-col justify-between gap-4 p-3 shadow-card shadow-shadow-700 md:gap-5 md:p-6;
    @apply text-xs font-normal text-secondary-dark-grey-500 md:text-base;
  }

  h2 {
    @apply text-lg font-semibold text-secondary-grey-900;
  }
  h3 {
    @apply text-base font-semibold text-secondary-grey-900 md:text-2xl;
    span {
      @apply text-secondary-dark-grey-500;
    }
  }
  h6 {
    @apply text-sm font-semibold text-secondary-grey-900 md:text-lg;
  }

  &__count {
    @apply flex justify-evenly text-xs font-medium text-secondary-dark-grey-500 md:text-sm md:font-normal;
    div {
      @apply flex flex-col items-center gap-1;
      h4 {
        @apply text-base font-semibold text-secondary-grey-900 md:text-xl;
      }
    }
  }

  &__profile {
    @apply flex items-center gap-2 text-xs text-secondary-dark-grey-500 md:hidden;
    img {
      @apply h-10 w-10 rounded-full;
    }
  }
}
