.Quizzes {
  @apply flex h-full w-full items-center justify-center md:px-7 md:py-10 xl:px-14 xl:py-20;

  &__wrapper {
    @apply flex h-full w-full flex-col gap-[60px] md:gap-10;
    @apply rounded-[32px] border-2 border-secondary-grey-500 p-4 md:px-12 md:py-8;
    @apply text-base font-medium text-secondary-dark-grey-900 md:text-2xl;
  }

  &__timer {
    @apply h-[42px] w-[42px] md:h-[94px] md:w-[94px] md:p-0;
    span {
      @apply font-bold text-primary-purple-blue-500;
    }
  }
}
