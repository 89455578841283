.QuizStart {
  @apply flex w-full flex-col items-center justify-center gap-4 rounded-[30px] bg-white py-4 md:gap-8 md:p-10 md:shadow-md md:shadow-shadow-700;

  &__head {
    @apply flex flex-col items-center justify-center gap-2 self-stretch;
    @apply self-stretch text-center text-sm font-semibold text-secondary-dark-grey-500 md:text-base;
    h1 {
      @apply text-2xl font-semibold text-black md:text-3xl;
    }
  }

  &__days {
    @apply grid grid-cols-2 items-start gap-3 self-stretch md:grid-cols-3;
    div {
      &:last-child {
        @apply col-span-2 md:col-span-3;
      }
    }
  }
}
