.MButton {
  @apply box-border flex max-h-[42px] items-center justify-center gap-2 text-nowrap rounded-2xl px-6 text-base font-semibold outline-none transition duration-200;

  &.highlighted {
    @apply border-2;
    &.primary {
      @apply border-primary-purple-blue-500 bg-primary-purple-blue-500 text-white;
      @apply hover:border-primary-purple-blue-400 hover:bg-primary-purple-blue-400;
      @apply active:border-primary-purple-blue-600 active:bg-primary-purple-blue-600;
      @apply focus:border-primary-purple-blue-600 focus:bg-primary-purple-blue-600;

      svg {
        @apply fill-white;
      }
    }
    &.grey {
      @apply border-gray-500 bg-gray-500 text-white;
      @apply hover:border-gray-500 hover:bg-gray-700;
      @apply active:border-gray-700 active:bg-gray-700;
      @apply focus:border-gray-700 focus:bg-gray-700;
    }
    &.danger {
      @apply border-red-500 bg-red-500 text-white;
      @apply hover:border-red-700 hover:bg-red-700;
      @apply active:border-red-700 active:bg-red-700;
      @apply focus:border-red-700 focus:bg-red-700;
    }
    &.success {
      @apply border-green-500 bg-green-500 text-white;
      @apply hover:border-green-500 hover:bg-green-700;
      @apply active:border-green-700 active:bg-green-700;
      @apply focus:border-green-700 focus:bg-green-700;
    }
    &.white {
      @apply border-primary-white bg-primary-white text-primary-purple-blue-500;

      svg {
        @apply fill-primary-purple-blue-500;
      }

      &:hover {
        @apply text-primary-purple-blue-400;
        svg {
          @apply fill-primary-purple-blue-400;
        }
      }

      &:active,
      &:focus {
        @apply text-primary-purple-blue-600;
        svg {
          @apply fill-primary-purple-blue-600;
        }
      }
    }
    &.tab {
      @apply border-secondary-dark-grey-900 bg-secondary-dark-grey-900 text-white;
      @apply hover:border-secondary-dark-grey-700 hover:bg-secondary-dark-grey-700;
    }
  }

  &.outline {
    @apply border-2;
    &.primary {
      @apply border-primary-purple-blue-500 text-primary-purple-blue-500;

      svg {
        @apply fill-primary-purple-blue-500;
      }

      &:hover {
        @apply border-primary-purple-blue-400 text-primary-purple-blue-400;

        svg {
          @apply fill-primary-purple-blue-400;
        }
      }

      &:active,
      &:focus {
        @apply border-primary-purple-blue-600 text-primary-purple-blue-600;

        svg {
          @apply fill-primary-purple-blue-600;
        }
      }
    }
    &.grey {
      @apply border-gray-500 text-gray-500;
      @apply hover:border-gray-700 hover:text-gray-700;
      @apply active:border-gray-700 active:text-gray-700;
      @apply focus:border-gray-700 focus:text-gray-700;
    }
    &.danger {
      @apply border-red-500 text-red-500;
      @apply hover:border-red-700 hover:text-red-700;
      @apply active:border-red-700 active:text-red-700;
      @apply focus:border-red-700 focus:text-red-700;
    }
    &.success {
      @apply border-green-500 text-green-500;
      @apply hover:border-green-700 hover:text-green-700;
      @apply active:border-green-700 active:text-green-700;
      @apply focus:border-green-700 focus:text-green-700;
    }
    &.white {
      @apply border-primary-white bg-primary-white text-primary-purple-blue-500;

      svg {
        @apply fill-primary-purple-blue-500;
      }

      &:hover {
        @apply text-primary-purple-blue-400;
        svg {
          @apply fill-primary-purple-blue-400;
        }
      }

      &:active,
      &:focus {
        @apply text-primary-purple-blue-600;
        svg {
          @apply fill-primary-purple-blue-600;
        }
      }
    }
  }

  &.secondary {
    @apply border-2;
    &.primary {
      @apply border-primary-purple-blue-100 bg-primary-purple-blue-100 text-primary-purple-blue-500;

      svg {
        @apply fill-primary-purple-blue-500;
      }

      &:hover {
        @apply hover:text-primary-purple-blue-400;

        svg {
          @apply fill-primary-purple-blue-400;
        }
      }

      &:active,
      &:focus {
        @apply border-primary-purple-blue-300 text-primary-purple-blue-600;
      }
    }
    &.grey {
      @apply border-gray-100 bg-gray-100 text-gray-600;
      @apply hover:border-gray-600 hover:text-gray-700;
      @apply active:border-gray-600 active:text-gray-600;
      @apply focus:border-gray-600 focus:text-gray-600;
    }
    &.danger {
      @apply border-red-100 bg-red-100 text-red-600;
      @apply hover:border-red-600 hover:text-red-700;
      @apply active:border-red-600 active:text-red-600;
      @apply focus:border-red-600 focus:text-red-600;
    }
    &.success {
      @apply border-green-100 bg-green-100 text-green-600;
      @apply hover:border-green-600 hover:text-green-700;
      @apply active:border-green-600 active:text-green-600;
      @apply focus:border-green-600 focus:text-green-600;
    }
    &.white {
      @apply bg-primary-white text-primary-purple-blue-300;
      @apply hover:text-primary-purple-blue-200;
      @apply active:text-primary-purple-blue-400;
      @apply focus:text-primary-purple-blue-400;
    }
    &.tab {
      @apply border-secondary-grey-300 bg-secondary-grey-300 text-secondary-grey-900;
      @apply hover:border-secondary-dark-grey-700 hover:bg-secondary-dark-grey-700 hover:text-white;
    }
  }

  &.transparent {
    &.primary {
      @apply text-purple-500 hover:text-purple-700 active:text-purple-700;
    }
    &.grey {
      @apply text-gray-500 hover:text-gray-700 active:text-gray-700;
    }
    &.danger {
      @apply text-red-500 hover:text-red-700 active:text-red-700;
    }
    &.success {
      @apply text-green-500 hover:text-green-700 active:text-green-700;
    }
    &.white {
      @apply text-primary-purple-blue-500 hover:text-primary-purple-blue-400 active:text-primary-purple-blue-600;
    }
  }

  &.disabled {
    @apply pointer-events-none opacity-50;
  }

  &.xs {
    @apply px-4 py-2 text-sm;
  }
  &.sm {
    @apply px-4 py-2 text-sm sm:px-6 sm:py-3;
  }
  &.md {
    @apply px-4 py-2 text-sm sm:px-6 sm:py-3 md:px-7 md:py-3.5 md:text-base;
  }
  &.lg {
    @apply px-4 py-2 text-sm sm:px-6 sm:py-3 md:px-7 md:py-3.5 md:text-base lg:px-8 lg:py-4 lg:text-lg;
  }

  .Loader {
    @apply w-auto;
  }
}
