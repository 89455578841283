.NFTView {
  @apply mb-4 flex flex-col gap-3 md:mb-5 md:flex-row md:gap-5;

  h1 {
    @apply self-stretch text-4xl font-bold text-black-10;
  }

  &__img {
    @apply h-full max-h-[700px] w-full max-w-[700px] p-0;
  }

  &__school {
    @apply col-span-1 flex w-full flex-col gap-4 md:col-span-5 md:gap-5;
  }

  &__purchase {
    @apply flex flex-col items-end gap-4 rounded-3xl bg-white p-4 text-secondary-dark-grey-500 shadow-md shadow-shadow-700 md:flex-row md:p-6;
    div {
      @apply flex w-full flex-[1_0_0] flex-col items-start gap-3 self-end text-base font-medium;

      h4 {
        @apply self-stretch text-base font-bold text-black-10 md:text-lg;
      }

      span {
        @apply text-center text-base font-semibold text-secondary-dark-grey-500;
      }
    }
  }

  &__list {
    @apply flex flex-col items-start justify-center gap-4 self-stretch rounded-3xl bg-white p-4 shadow-md shadow-shadow-700;
  }
}
