.ProfileFollowerCard {
  @apply flex items-center justify-start gap-2 rounded-2xl p-2 shadow-card shadow-shadow-700 md:gap-4 md:p-3.5;
  @apply text-xs font-medium text-secondary-dark-grey-500 md:text-sm;

  a {
    @apply text-primary-purple-blue-500 visited:text-primary-purple-blue-400 hover:text-primary-purple-blue-700;
  }

  &__img {
    @apply h-[34px] w-[34px] flex-none overflow-hidden rounded-full md:h-[46px] md:w-[46px] md:p-0;
    img {
      @apply block h-full w-full object-cover;
    }
  }

  &__content {
    @apply max-w-[36vw] overflow-hidden sm:max-w-[55vw] lg:w-full;
    h6 {
      @apply overflow-hidden text-ellipsis text-nowrap text-sm font-bold text-secondary-grey-900 md:text-base;
    }
    span {
      @apply overflow-hidden text-ellipsis text-nowrap md:p-0;
    }
  }

  &__icon {
    @apply ml-auto flex-none;
    svg {
      @apply h-3.5 w-3.5 md:h-[16.8px] md:w-[16.8px] md:p-0;
    }
  }
}
