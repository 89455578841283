.ScienceSecondaryCard {
  @apply flex flex-shrink-0 flex-col items-start gap-2 rounded-xl border bg-white p-2 shadow shadow-shadow-700 md:gap-4 md:rounded-3xl md:p-4;

  &__content {
    @apply flex flex-[1_0_0] flex-col items-start self-stretch text-xs font-medium text-secondary-grey-800 md:gap-2.5 md:text-base;
    h4 {
      @apply text-lg font-medium text-black md:text-2xl;
    }
  }

  a {
    @apply ml-auto self-stretch text-base font-medium text-blue-600 md:text-xl;
  }
}
