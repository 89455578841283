.Badge {
  @apply flex cursor-pointer items-center justify-center gap-[5px] rounded-full px-3 py-[6px] text-xs font-semibold outline-none;

  svg {
    @apply h-[7px] p-0;
  }

  &.sm {
    @apply px-2 py-[3px] opacity-100;
  }
  &.md {
    @apply px-2.5 py-1;
  }
  &.lg {
    @apply px-3 py-1.5 opacity-100;
  }
  &.xl {
    @apply px-4 py-2 opacity-100;
  }

  &.primary {
    @apply bg-primary-purple-blue-100 text-primary-purple-blue-500;
    svg {
      @apply fill-primary-purple-blue-500;
      path {
        @apply stroke-primary-purple-blue-500;
      }
    }
    &.active {
      @apply bg-primary-purple-blue-500 text-white;
      svg {
        @apply fill-white;
        path {
          @apply stroke-white;
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        @apply bg-primary-purple-blue-500 text-white;
        svg {
          @apply fill-white;
          path {
            @apply stroke-white;
          }
        }
      }
      @media (hover: none) and (pointer: coarse) {
        &:active {
          @apply bg-primary-purple-blue-500 text-white;
          svg {
            @apply fill-white;
            path {
              @apply stroke-white;
            }
          }
        }
      }
    }
  }
  &.danger {
    @apply bg-primary-red-100 text-primary-red-500;
    svg {
      @apply fill-primary-red-500;
      path {
        @apply stroke-primary-red-500;
      }
    }
    &.active {
      @apply bg-primary-red-500 text-white;
      svg {
        @apply fill-white;
        path {
          @apply stroke-white;
        }
      }
    }
    @media (hover: hover) {
      &:hover {
        @apply bg-primary-red-500 text-white;
        svg {
          @apply fill-white;
          path {
            @apply stroke-white;
          }
        }
      }
    }
    @media (hover: none) and (pointer: coarse) {
      &:active {
        @apply bg-primary-red-500 text-white;
        svg {
          @apply fill-white;
          path {
            @apply stroke-white;
          }
        }
      }
    }
  }
  &.outline {
    @apply border border-solid border-secondary-grey-500 bg-transparent text-secondary-dark-grey-500;
    svg {
      @apply fill-secondary-dark-grey-500;
      path {
        @apply stroke-secondary-dark-grey-500;
      }
    }

    &.active {
      @apply border border-solid border-secondary-dark-grey-500 bg-transparent text-secondary-grey-800;
      svg {
        @apply fill-secondary-grey-800;
      }
    }
    @media (hover: hover) {
      &:hover {
        @apply border border-solid border-secondary-dark-grey-500 bg-transparent text-secondary-grey-800;
        svg {
          @apply fill-secondary-grey-800;
        }
      }
    }
    @media (hover: none) and (pointer: coarse) {
      &:active {
        @apply border border-solid border-secondary-dark-grey-500 bg-transparent text-secondary-grey-800;
        svg {
          @apply fill-secondary-grey-800;
        }
      }
    }
  }

  &.disabled {
    @apply pointer-events-none cursor-default opacity-70;
  }
}
